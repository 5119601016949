<template>
  <StoreSelect type="dialog" v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, selectedStore }">
      <v-list-item v-on="on">
        <v-list-item-title v-text="get(selectedStore, `name`)" />
        <v-list-item-action>
          <StoreAvatar :store-id="get(selectedStore, `id`)" :size="24" />
        </v-list-item-action>
      </v-list-item>
    </template>
  </StoreSelect>
</template>
<script>
import get from "lodash/get";

export default {
  components: {
    StoreSelect: () => import("./StoreSelect"),
    StoreAvatar: () => import("./StoreAvatar"),
  },
  inheritAttrs: false,
  methods: { get },
};
</script>
